import { Link } from 'gatsby'
import React from 'react'
import styled, { keyframes } from 'styled-components'

const normalizeUrl = (url) => {
  const baseUrl = 'https://www.unicollege.co.za'
  return url.startsWith('http') 
    ? url.startsWith(baseUrl) ? url.replace(baseUrl, '') : url
    : url.startsWith('/') ? url : `/${url}`
}

const PageHighlightLinks = ({ links }) => {
  const sortedLinks = [...links].sort((a, b) => a.order - b.order)
  const middleIndex = Math.floor(sortedLinks.length / 2)

  return (
    <StyledPageHighlightLinks>
      {sortedLinks.map((link, index) => {
        const normalizedLink = normalizeUrl(link?.linkTo || '')
        const isExternal = normalizedLink.startsWith('http')
        const LinkComponent = isExternal ? 'a' : Link
        const linkProps = isExternal 
          ? { href: normalizedLink, target: "_blank", rel: "noopener noreferrer" }
          : { to: normalizedLink }

        const animationClass = index < middleIndex ? 'fade-in-left' : 'fade-in-right'

        return (
          <LinkComponent 
            key={link._key}
            {...linkProps}
            style={{ background: link?.buttonColor?.value }}
            className={animationClass}
          >
            {link.name}
          </LinkComponent>
        )
      })}
    </StyledPageHighlightLinks>
  )
}

const fadeInLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`

const fadeInRight = keyframes`
  from {
    opacity: 0;
    transform: translateX(50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`

const StyledPageHighlightLinks = styled.section`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 1rem;

  a {
    flex: 1;
    display: inline-block;
    background: var(--primary-color);
    color: #fafafa;
    min-width: 200px;
    padding: 2em 0;
    font-size: 1.6rem;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    transition: all 0.3s ease;
    position: relative;
    overflow: hidden;

    &.fade-in-left {
      animation: ${fadeInLeft} 0.6s ease-out forwards;
      animation-delay: calc(var(--index, 0) * 0.2s);
    }

    &.fade-in-right {
      animation: ${fadeInRight} 0.6s ease-out forwards;
      animation-delay: calc(var(--index, 0) * 0.2s);
    }

    &:hover {
      transform: translateY(-5px);
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
      
      &::after {
        transform: scaleX(1);
      }
    }

    &::before,
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      width: 50%;
      height: 4px;
      background: rgba(255, 255, 255, 0.3);
      transform: scaleX(0);
      transition: transform 0.3s ease;
    }

    &::before {
      left: 50%;
      transform-origin: left;
    }

    &::after {
      right: 50%;
      transform-origin: right;
    }

    &:hover::before,
    &:hover::after {
      transform: scaleX(1);
    }
  }

  @media (max-width: 1024px) {
    flex-wrap: wrap;
  }

  @media (max-width: 480px) {
    a {
      font-size: 1.25rem;
    }
  }
`

export default PageHighlightLinks