import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

import upcomingCoursesImg from '../../images/home-card-links/upcoming-courses-card.png'
import meetOurTeamImg from '../../images/home-card-links/meet-our-team-card.png'
import ourCampusImg from '../../images/home-card-links/our-campus-card.png'

const HomeCardLinks = () => {
  return (
    <StyledHomeCardLinks>
      <Link to='/upcoming-courses/' className="card">
        <img src={upcomingCoursesImg} alt="upcoming courses" />
        <h3>Upcoming Courses</h3>
      </Link>
      <Link to='/our-team/' className="card">
        <img src={meetOurTeamImg} alt="meet our team" />
        <h3>Meet Our Team</h3>
      </Link>
      <Link to='/our-campus/' className="card">
        <img src={ourCampusImg} alt="our campus" />
        <h3>Our Campus</h3>
      </Link>
    </StyledHomeCardLinks>
  )
}

const StyledHomeCardLinks = styled.section`
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  gap: 2rem;
  padding: 6rem;

  a.card {
    text-decoration: none;

    img {
      margin-bottom: 1rem;
    }

    h3 {
      text-align: center;
      text-transform: uppercase;
    }
  }
`

export default HomeCardLinks
