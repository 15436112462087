import React from "react";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import styled from "styled-components";
import { PortableText } from "@portabletext/react";

import { Navigation, Pagination, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

// import showcaseImg from '../../images/showcase/showcase-image.png'
// import mobileShowcaseImg from '../../images/showcase/mobile-showcase.png'
// import useMediaQuery from '../../hooks/use-media-query';

const components = {
  marks: {
    fontOptions: ({ children, value }) => {
      const style = {
        ...(value.color && { color: value.color.hex }),
        ...(value.weight && { fontWeight: value.weight }),
        // ...value.fontSize && { fontSize: `${value.fontSize.size}${value.fontSize.unit}` }
      };

      return <span style={style}>{children}</span>;
    },
  },
};

const Showcase = ({ slides }) => {
  const slideElements = slides.map((data) => {
    const {
      order,
      backgroundImage,
      mobileBackgroundImage,
      _rawContent,
      contentAlignment,
      textAlign,
      linkButton,
    } = data;

    return (
      <SwiperSlide key={order}>
        {/* Both images need to be rendered. And their visibilty get handled by CSS through their id. useMediaQuery does not work. It shows the desktop image when you first land on the site. */}
        <GatsbyImage
          image={getImage(mobileBackgroundImage?.asset)}
          alt=""
          backgroundColor="#E8ECF0"
          style={{ gridArea: "1/1", zIndex: "-1" }}
          className="mobile-showcase-image"
        />
        <GatsbyImage
          image={getImage(backgroundImage?.asset)}
          alt=""
          backgroundColor="#E8ECF0"
          style={{ gridArea: "1/1", zIndex: "-1" }}
          className="desktop-showcase-image"
        />

        <div
          className={`content ta-${textAlign} ${
            contentAlignment ? `ca-${contentAlignment}` : ""
          }`}
        >
          <div className="content-text">
            <PortableText value={_rawContent} components={components} />
          </div>
          {linkButton.link.linkType === "internal" ? (
            <Link to={linkButton.link.internalLink.current} className="btn-showcase">
              {linkButton.text}
            </Link>
          ) : (
            <a href={linkButton.link.href} className="btn-showcase">
              {linkButton.text}
            </a>
          )}
        </div>
      </SwiperSlide>
    );
  });

  return (
    <StyledSwiper
      modules={[Navigation, Pagination, Autoplay]}
      spaceBetween={0}
      slidesPerView={1}
      speed={500}
      navigation
      loop={slides.length > 1}
      autoplay={{ delay: 5000 }}
      pagination={{ clickable: true }}
    >
      {slideElements}
    </StyledSwiper>
  );
};

const StyledSwiper = styled(Swiper)`
  aspect-ratio: 2.5 / 1;

  .mobile-showcase-image {
    display: none;
  }

  .ta-center {
    text-align: center;
  }

  .ta-right {
    text-align: end;
  }

  .btn-showcase {
    display: inline-block;
    background: var(--primary-color);
    color: #fafafa;
    padding: 0.4em 1em;
    margin-top: 1rem;
    width: fit-content;
    font-size: 1.6vw;
    font-weight: 600;
    text-decoration: none;

    box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.15);
    -webkit-box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.15);
  }

  .swiper-slide {
    display: grid;

    .content {
      grid-area: 1 / 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: absolute;
      top: 0;
      /* right: 0; */
      bottom: 0;
      width: 50%;
      padding: 3rem;
      text-shadow: 0px 0px 10px rgba(255, 255, 255, 0.25);

      &.ta-center {
        align-items: center;
      }
      &.ta-right {
        align-items: end;
      }

      &.ca-left {
        left: 0;
      }
      &.ca-right {
        right: 0;
      }

      h1 {
        font-size: 7vw;
      }
      h2 {
        font-size: 5vw;
      }
      h3 {
        font-size: 4vw;
      }
      h4 {
        font-size: 3vw;
      }
      h5 {
        font-size: 2vw;
      }
      h6 {
        font-size: 1.75vw;
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        /* line-height: 1; */
        margin: 0.4rem 0;
      }
    }
  }

  @media (max-width: 600px) {
    aspect-ratio: 1 / 1.5;

    .mobile-showcase-image {
      display: initial;
    }

    .desktop-showcase-image {
      display: none;
    }

    .ta-center,
    .ta-right {
      text-align: initial;
    }

    .btn-showcase {
      align-self: center;
      font-size: 5.33vw;
    }

    .swiper-slide {
      .content {
        justify-content: space-between;
        width: 100%;
        padding: 1.5rem 1rem 3rem 1rem;
        text-align: center;

        &.ta-center,
        &.ta-right {
          align-items: center;
        }

        h1 {
          font-size: 16vw;
        }
        h2 {
          font-size: 13vw;
        }
        h3 {
          font-size: 6.5vw;
        }
        h4 {
          font-size: 6vw;
        }
        h5 {
          font-size: 4.5vw;
        }
        h6 {
          font-size: 3.5vw;
        }
      }
    }
  }
`;

export default Showcase;
