import React from "react";
import styled from "styled-components";

import openQuoteImg from "../../images/quote-icons/open-quote.png";
import closeQuoteImg from "../../images/quote-icons/close-quote.png";

import { Pagination, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";

function TestimonialsCarousel({ testimonials }) {
  return (
    <StyledTestimonialsCarousel>
      <h3>What Our Students Say</h3>
      <Swiper
        modules={[Pagination, Autoplay]}
        spaceBetween={30}
        slidesPerView={1}
        pagination={{ clickable: true }}
        loop
        autoplay={{
          delay: 5000,
          disableOnInteraction: true,
        }}
      >
        {testimonials.map(({ _id, studentName, review }) => (
          <SwiperSlide key={_id}>
            <p className="quote">{review}</p>
            <p className="name">{studentName}</p>
          </SwiperSlide>
        ))}
      </Swiper>
      <img className="open-quote" src={openQuoteImg} alt="open quote" />
      <img className="close-quote" src={closeQuoteImg} alt="close quote" />
    </StyledTestimonialsCarousel>
  );
}

const StyledTestimonialsCarousel = styled.section`
  background: #e1e1e1;
  color: #3b3b3b;
  max-width: 1500px;
  margin: auto;
  padding: 1.8rem;
  position: relative;
  text-align: center;

  .open-quote,
  .close-quote {
    position: absolute;
    top: 1.8rem;
  }

  .open-quote {
    left: 6%;
  }
  .close-quote {
    right: 6%;
  }

  h3 {
    color: var(--primary-color);
    margin-bottom: 1.4rem;
    font-size: 2rem;
    text-transform: uppercase;
  }

  p.quote {
    margin-bottom: 1rem;
    line-height: 1.4;
  }

  p.name {
    font-size: 1.2rem;
    font-weight: 700;
  }

  .swiper {
    max-width: 1000px;
    padding-bottom: 40px;

    .swiper-pagination {
      bottom: 0;

      &-bullet {
        width: 12px;
        height: 12px;
        margin: 0 7px;
      }

      &-bullet-active {
        background-color: #3b3b3b;
      }
    }
  }
`;

export default TestimonialsCarousel;
