import React, { useState, useRef, useEffect, useCallback } from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import styled from 'styled-components'

const UpcomingCourses = () => {
  const [upcomingCourses, setUpcomingCourses] = useState([])
  const learningMethodInputRef = useRef(null)

  const data = useStaticQuery(graphql`
    query UpcomingCoursesComponentQuery {
      allSanityCourse(sort: {fields: courseStartDates___startDate}) {
        nodes {
          title
          slug { current }
          studyFields {
            slug { current }
          }
          courseStartDates {
            learningMethod {
              title
              slug { current }
            }
            startDate
          }
        }
      }
      allSanityLearningMethod(sort: {fields: rank}) {
        nodes {
          title
          slug {
            current
          }
        }
      }
    }
  `)

  const courses = data.allSanityCourse.nodes
  const learningMethods = data.allSanityLearningMethod.nodes

  const learningMethodOptionElements = learningMethods.map(({title, slug}) => (
    <option key={slug.current} value={slug.current}>{title}</option>
  ))

  const filterUpcomingCourses = useCallback(() => {
    const learningMethodInputValue = learningMethodInputRef.current.value

    const upcomingCoursesArr = courses.filter(course => {
      const learningMethods = course.courseStartDates.map(startDate => startDate.learningMethod.slug.current)

      if (learningMethods.includes(learningMethodInputValue)) {
        const startDate = course.courseStartDates.find(instance => instance.learningMethod.slug.current === learningMethodInputValue).startDate

        return new Date(startDate) > new Date()
      } else {
        return false
      }
    })

    setUpcomingCourses(upcomingCoursesArr.slice(0, 10))
  }, [courses])

  const handleSubmit = (event) => {
    event.preventDefault()
    filterUpcomingCourses()
  }

  useEffect(() => {
    filterUpcomingCourses()
  }, [filterUpcomingCourses])

  return (
    <StyledUpcomingCourses>
      <h2>Upcoming Courses</h2>

      <form onSubmit={handleSubmit}>
        <select name="" id="" ref={learningMethodInputRef} defaultValue='part-time-courses' onChange={handleSubmit}>
          {learningMethodOptionElements}
        </select>
        {/* <button type="submit">Search</button> */}
      </form>

      <ul className="upcoming-courses-list">
        {upcomingCourses.map(course => {
          const startDate = course.courseStartDates.find(instance => instance.learningMethod.slug.current === learningMethodInputRef.current.value).startDate

          return <li key={course.slug.current}>
            <Link to={`/${course.studyFields[0].slug.current}/${course.slug.current}/`}>
              <span className='title'>{course.title}</span>
              <span className='date'>{new Date(startDate).toLocaleDateString('en-ZA', {day: 'numeric', month: 'short', year: 'numeric'})}</span>
            </Link>
          </li>
        })}
      </ul>

      <Link to='/upcoming-courses/' className='view-all'>See all our upcoming courses {`>`}</Link>
    </StyledUpcomingCourses>
  )
}

const StyledUpcomingCourses = styled.article`
  display: inline-block;
  /* width: 33vw; */
  /* margin: 1rem; */

  a.view-all {
    color: var(--primary-color);
    font-weight: bold;
  }

  h2 {
    margin-bottom: 1rem;
    text-transform: uppercase;
    border-bottom: solid 2px var(--primary-color);
  }

  form {
    display: flex;
    align-items: space-between;
    gap: 1rem;
    margin-bottom: 1rem;

    select {
      display: block;
      color: var(--primary-color); // #999
      width: 100%;
      padding: 0.8em;
      font-size: 1.1rem;
      font-weight: 400;
      font-family: 'Montserrat';
      border: solid 1px var(--primary-color); // #ddd

      &:focus, &:active {
        outline-color: var(--primary-color);
      }
    }

    button[type='submit'] {
      flex: 1 0 auto;
      background: var(--primary-color);
      color: #f4f4f4;
      height: fit-content;
      padding: 0.9em 1.1em;
      border: none;
      font-size: 1.1rem;
      text-transform: uppercase;
      letter-spacing: 1px;
    }
  }

  ul {
    margin-bottom: 1rem;
    list-style: none;

    li {
      a {
        display: flex;
        justify-content: space-between;
        padding: 0.25rem 0;
        text-decoration: none;

        &:hover {
          color: var(--primary-color);
        }
      }

      span.date {
        white-space: nowrap;
      }
    }
  }

  @media (max-width: 1255px) {
    margin: 0 1rem;
  }
`

export default UpcomingCourses
