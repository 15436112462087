import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled from 'styled-components'

import truncate from '../helpers/truncate'

const LatestNews = () => {
  const data = useStaticQuery(graphql`
    query LatestnewsComponentQuery {
      allSanityArticle(sort: {fields: _createdAt, order: DESC}, limit: 2) {
        nodes {
          title
          slug { current }
          content {
            children {
              text
            }
          }
        }
      }
    }
  `)

  const articles = data.allSanityArticle.nodes

  const articleElements = articles.map(({title, slug, content}) => {
    const stringContent = content.map(item => item.children[0]?.text).join(' ')

    return (
      <article key={slug.current} className="article-card">
        <Link to={`/${slug.current}/`}>
          <h3>{title}</h3>
          <p>{truncate(stringContent, 200)}</p>
        </Link>
      </article>
    )
  })

  return (
    <StyledLatestNews>
      <h2>Latest News</h2>

      <div className="articles">
        {articleElements}
      </div>

      <Link to='/news/' className='view-all'>View All {`>`}</Link>
    </StyledLatestNews>
  )
}

const StyledLatestNews = styled.section`
  display: inline-block;
  /* padding: 1rem;
  width: 33vw; */

  a.view-all {
    color: var(--primary-color);
    font-weight: bold;
  }

  h2 {
    margin-bottom: 1rem;
    border-bottom: solid 2px var(--primary-color);
    text-transform: uppercase;
  }

  article.article-card {
    margin-bottom: 1rem;

    &:hover {
      h3 {
        color: var(--primary-color)
      }
    }

    a {
      text-decoration: none;
    }

    h3 {
      margin-bottom: 0.5rem;
    }

    p {
      /* max-height: 125px;
      overflow: hidden;
      text-overflow: ellipsis; */
      font-size: 0.95rem;
      line-height: 1.4;
    }
  }

  @media (max-width: 1255px) {
    margin: 1rem;
  }
`

export default LatestNews
