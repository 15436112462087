import { graphql, Link, useStaticQuery } from 'gatsby'
import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'

const ChooseYourCourse = ({ learningMethods, studyFields}) => {
  const learningMethodInputRef = useRef(null)
  const fieldOfStudyInputRef = useRef(null)
  const [ courseOptions, setCourseOptions ] = useState(null)
  const [ courseOptionElements, setCourseOptionElements ] = useState([])

  const data = useStaticQuery(graphql`
    query MyQuery {
      allSanityCourse {
        nodes {
          title
          slug { current }
          studyFields {
            title
            slug { current }
          }
          courseStartDates {
            learningMethod {
              title
              slug { current }
            }
          }
        }
      }
    }
  `)
  const courses = data.allSanityCourse.nodes

  useEffect(() => {
    if (courseOptions) {
      setCourseOptionElements(courseOptions.map(({title, slug, studyFields}) => (
        <li key={slug.current}><Link to={`/${studyFields[0].slug.current}/${slug.current}/`}>{title}</Link></li>
      )))
    }
  }, [courseOptions])

  const getCourseOptions = () => {
    const learningMethodValue = learningMethodInputRef.current.value
    const fieldOfStudyValue = fieldOfStudyInputRef.current.value

    const courseOptionsArr = courses.filter(course => {
      const courseLearningMethods = course.courseStartDates.map(startDate => startDate.learningMethod.slug.current)
      const courseStudyFields = course.studyFields.map(field => field.slug.current)

      return courseLearningMethods.includes(learningMethodValue) && courseStudyFields.includes(fieldOfStudyValue)
    })

    setCourseOptions(courseOptionsArr)
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    getCourseOptions()
  }

  return (
    <StyledChooseYourCourse>
      <h3>Choose Your Course</h3>

      <div className="container">
        <form onSubmit={handleSubmit}>
          <fieldset>
            <span>
              <label htmlFor="learning-method">Learning Method</label>
              <select name="learning-method" id="learning-method" ref={learningMethodInputRef} required style={{ fontSize: '1rem' }}>
                <option value="">Select One</option>
                {learningMethods.map(method => (
                  <option key={method.slug.current} value={method.slug.current}>{method.title}</option>
                ))}
              </select>
            </span>

            <span>
              <label htmlFor="study-field">Field of Study</label>
              <select name="study-field" id="study-field" ref={fieldOfStudyInputRef} required style={{ fontSize: '1rem' }}>
                <option value="">Select One</option>
                {studyFields.map(field => (
                  <option key={field.slug.current} value={field.slug.current}>{field.title}</option>
                ))}
              </select>
            </span>
          </fieldset>

          <button type="submit">Search</button>
        </form>

        {courseOptions &&
          <div className="course-options">
            <h4>Your Course Options</h4>
            <ul>
              {courseOptions?.length > 0 
              ? courseOptionElements
              : <li>No Courses Found</li>}
            </ul>
          </div>
        }
      </div>
    </StyledChooseYourCourse>
  )
}

const StyledChooseYourCourse = styled.div`
  padding: 4rem;

  h3 {
    margin-bottom: 2rem;
    font-size: 2.2rem;
    text-align: center;
    text-transform: uppercase;
  }

  div.container {
    max-width: 1000px;
    margin: auto;
  }

  form {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-end;
    gap: 2rem;
    /* row-gap: 1rem; */
    margin-bottom: 2rem;

    fieldset {
      flex-grow: 10;
      display: flex;
      flex-flow: row wrap;
      column-gap: 2rem;
      row-gap: 1rem;
      border: none;

      span {
        flex: 1 1 auto;

        label {
          text-transform: uppercase;
        }
      }
    }

    select {
      display: block;
      color: #999;
      width: 100%;
      margin-top: 0.6rem;
      padding: 0.8em;
      font-size: 1.1rem;
      font-weight: 400;
      font-family: 'Montserrat';
      border: solid 1px #ddd;
    }

    button[type='submit'] {
      flex: 1 0 auto;
      background: var(--primary-color);
      color: #f4f4f4;
      height: fit-content;
      padding: 0.9em 1.1em;
      border: none;
      font-size: 1.1rem;
      text-transform: uppercase;
      letter-spacing: 1px;
    }
  }

  div.course-options {
    a {
      text-decoration: none;
    }

    h4 {
      margin-bottom: 1rem;
      font-size: 1.1rem;
    }

    ul {
      list-style: none;

      li {
        line-height: 1.5;
      }
    }
  }

  @media (max-width: 480px) {
    padding: 4rem 2rem;
  }
`

export default ChooseYourCourse
