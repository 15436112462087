import React from 'react'
import styled from 'styled-components'

import backgroundImg from '../../images/why-choose-unicollege-background.png'

const WhyChooseUnicollege = () => {
  return (
    <StyledWhyChooseUnicollege>
      <div className='why-choose-unicollege'>
        <h3>Why Choose Unicollege?</h3>
        <p>Whether you are studying full-time, part-time or online, our learning method is engaging and interactive, creating skills and instilling knowledge that is based on real understanding rather than theory alone.</p>
        <p>Our management and lecturing staff use their many years of accumulated experience in education to develop, customise and enhance our courses, our teaching methods and our support services to ensure that our students are well-prepared for success in the working world.</p>
        <p>Open your mind to UniCollege and explore your study opportunities with us!</p>
      </div>

      <ul>
        <li>Accredited Qualifications</li>
        <li>Qualified Lecturers with Industry Experience</li>
        <li>Affordable Payment Plans</li>
        <li>Safe & Secure Campus</li>
        <li>Resource Centre & Free WiFi</li>
        <li>Study Materials Included</li>
        <li>Outstanding Student Support</li>
        <li>UniCollege Accreditation</li>
      </ul>

      <img src={backgroundImg} alt="" />
    </StyledWhyChooseUnicollege>
  )
}

const StyledWhyChooseUnicollege = styled.section`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 1rem;
  position: relative;
  color: #f4f4f4;
  padding: 4rem;

  div.why-choose-unicollege {
    max-width: 700px;

    h3 {
      color: inherit;
      font-size: 2.6rem;
      text-transform: uppercase;
    }

    p {
      color: inherit;
      margin: 0.8rem 0;
      line-height: 1.4;
    }
  }

  ul {
    color: inherit;
    list-style: none;

    li {
      font-size: 1.5rem;
      margin: 0.6rem 0;
    }
  }

  img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
  }

  @media (max-width: 1024px) {
    align-items: initial;
    flex-direction: column;
  }

  @media (max-width: 480px) {
    padding: 4rem 2rem;
  }
`

export default WhyChooseUnicollege
