import { Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import formatLearningMethodTitle from '../../helpers/formatLearningMethodTitle'

const LearningMethodButtonsAlt = ({ learningMethods }) => (
  <StyledLearningMethodButtonsAlt>
    <div className="container">
      {learningMethods.map(method => (
        <div key={method.slug.current} className="btn-with-description">
          <Link to={`/learning-methods/${method.slug.current}/`} style={{backgroundColor: method.themeColour}}>
            {formatLearningMethodTitle(method.title)}
          </Link>
          <p>{method.shortDescription}</p>
        </div>
      ))}
    </div>
  </StyledLearningMethodButtonsAlt>
)

const StyledLearningMethodButtonsAlt = styled.section`
  background-color: #EFEFEF;
  padding: 2.4rem;

  div.container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1.4rem;
    max-width: 1500px;
    margin: auto;
  }

  div.btn-with-description {
    display: flex;
    align-items: center;

    a {
      flex-shrink: 0;
      display: inline-block;
      background: var(--primary-color);
      color: #fafafa;
      width: 14rem;
      margin-right: 2rem;
      padding: 1em 0;
      border-radius: 5px;
      text-align: center;
      text-transform: uppercase;
      text-decoration: none;

      &.blue { background-color: #312668; }
      &.red { background-color: var(--primary-color); }
      &.orange { background-color: #F35E02; }
      &.grey { background-color: #767676; }
    }

    p {
      display: inline-block;
    }
  }

  @media (max-width: 768px) {
    div.container {
      grid-template-columns: 1fr;

      div.btn-with-description {
        flex-direction: column;

        a {
          width: 100%;
          margin-right: 0;
          margin-bottom: 1rem;
        }
      }
    }
  }
`

export default LearningMethodButtonsAlt
